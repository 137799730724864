// Generated by Framer (d2b7042)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["gziou4GB5"];

const serializationHash = "framer-wqG43"

const variantClassNames = {gziou4GB5: "framer-v-1sx7i08"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, title, width, ...props}) => { return {...props, GPO8Kvkca: title ?? props.GPO8Kvkca ?? "Warren to the Moon"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, GPO8Kvkca, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "gziou4GB5", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1sx7i08", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"gziou4GB5"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "3px", "--border-color": "var(--token-3769711a-c3d5-444b-8a14-c0d7e2492513, rgb(102, 102, 102))", "--border-left-width": "3px", "--border-right-width": "3px", "--border-style": "solid", "--border-top-width": "3px", backdropFilter: "blur(0px)", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, WebkitBackdropFilter: "blur(0px)", ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 style={{"--font-selector": "R0Y7QW50b24tcmVndWxhcg==", "--framer-font-family": "\"Anton\", \"Anton Placeholder\", sans-serif", "--framer-font-size": "24px", "--framer-text-color": "var(--extracted-gdpscs, var(--token-3769711a-c3d5-444b-8a14-c0d7e2492513, rgb(102, 102, 102)))"}}>Available for work</motion.h1></React.Fragment>} className={"framer-7lio94"} fonts={["GF;Anton-regular"]} layoutDependency={layoutDependency} layoutId={"zDne0jNBz"} style={{"--extracted-gdpscs": "var(--token-3769711a-c3d5-444b-8a14-c0d7e2492513, rgb(102, 102, 102))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={GPO8Kvkca} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-wqG43[data-border=\"true\"]::after, .framer-wqG43 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-wqG43.framer-yehurr, .framer-wqG43 .framer-yehurr { display: block; }", ".framer-wqG43.framer-1sx7i08 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 12px 18px 12px 18px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-wqG43 .framer-7lio94 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-wqG43.framer-1sx7i08 { gap: 0px; } .framer-wqG43.framer-1sx7i08 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-wqG43.framer-1sx7i08 > :first-child { margin-left: 0px; } .framer-wqG43.framer-1sx7i08 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 53
 * @framerIntrinsicWidth 226
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"GPO8Kvkca":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerZVyrGvOsH: React.ComponentType<Props> = withCSS(Component, css, "framer-wqG43") as typeof Component;
export default FramerZVyrGvOsH;

FramerZVyrGvOsH.displayName = "Availability Stamp";

FramerZVyrGvOsH.defaultProps = {height: 53, width: 226};

addPropertyControls(FramerZVyrGvOsH, {GPO8Kvkca: {defaultValue: "Warren to the Moon", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerZVyrGvOsH, [{family: "Anton", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/anton/v25/1Ptgg87LROyAm0Kx8i4gS7lu.woff2", weight: "400"}])